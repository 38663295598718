import React,{useState} from 'react'
import "../styles/main.css"
import { IoAddOutline } from 'react-icons/io5';
import {useNavigate  } from "react-router-dom";


export default function CreatePage() {
    let navigate = useNavigate();
    const CreatePageHandle = () =>{
        navigate("/CreatePage", { replace: true })
    }
  return (
    <div className="createpagediv" id="createpagediv">
        <div className="createpagecircle" onClick={CreatePageHandle} >
            <IoAddOutline className="addicon" size={"50px"} />
        </div>
        <span className="createpagetext">Create Page</span>
    </div>  
    )
}
