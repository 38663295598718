import axios from "axios";
import { API_HOST } from "../../api_utils";

export const TYPES = {
  ClickedPage:"ClickedPage",
  ClickedPageError:"ClickedPageError",
  PagesList:"PagesList",
  DeletedPage:"DeletedPage",
  RenamePage:"RenamePage",
  FetchingPages:"FetchingPages"
};

export const clickpage =(page) =>async (dispatch)=>{
  dispatch({ type: TYPES.ClickedPage, data: page })
}

export const getpages = (numberofpages) =>async (dispatch) => {
    const token=localStorage.getItem('user')
    const headers = {authorization: 'Bearer '.concat(token)}
    console.log(numberofpages)
    dispatch({ type: TYPES.FetchingPages, data:{isFetching:true} })
    await axios.get(`${API_HOST}pages/getpages/${numberofpages}`,{headers}).then((response)=>{
      if(response.data.error){
        window.localStorage.removeItem('user');
        window.open("/login","_self");
      }else{
        dispatch({ type: TYPES.PagesList, data: response.data })
      }
    });
}
export const deletepage = (pageid) =>async (dispatch) => {
  const token=localStorage.getItem('user')
  const headers = {authorization: 'Bearer '.concat(token)}
  await axios.delete(`${API_HOST}pages/${pageid}`,{headers}).then((response)=>{
    if(response.data.error){
      window.localStorage.removeItem('user');
      window.open("/login","_self");
    }else{
      dispatch({ type: TYPES.DeletedPage, data:pageid })
    }
  });
}
export const renamepage = (pageid,name) =>async (dispatch) => {
  const token=localStorage.getItem('user')
  const headers = {authorization: 'Bearer '.concat(token)}
  await axios.put(`${API_HOST}pages/${pageid}`,{name},{headers}).then((response)=>{
    if(response.data.error){
      window.localStorage.removeItem('user');
      window.open("/login","_self");
    }else{
      dispatch({ type: TYPES.RenamePage, data:{pageid:pageid,name:name} })

    }
  });
}
