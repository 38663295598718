import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_HOST } from "../api_utils";
import Sidebar from "../components/Sidebar";
import TopNav from "../components/TopNav";
import geditorConfig from "../api_utils/geditor_config";
import "../styles/main.css";
import $ from "jquery";
import montylogo from "../assets/MONTYLOGO.png";
import CreatePageLoader from "../components/CreatePageLoader";

const Editor = () => {
  let navigate = useNavigate();

  const [editor, setEditor] = useState(null);
  const [render, setrender] = useState(false);
  const [assets, setAssets] = useState([]);
  const { pageId } = useParams();
  const checkid = async (pageId) => {
    const token = localStorage.getItem("user");
    const headers = { authorization: "Bearer ".concat(token) };
    const res = await axios
      .post(`${API_HOST}pages/check`, { pageId }, { headers })
      .then((response) => {
        if (response.data.error) {
          window.localStorage.removeItem("user");
          window.open("/login", "_self");
        }
        return response.data;
      });
    return res;
  };

  useEffect(async () => {
    const check = await checkid(pageId);
    if (!check) {
      navigate(`/pages`, { replace: true });
      return;
    } else {
      setrender(true);
    }
    const editor = geditorConfig(pageId);
    setEditor(editor);
  }, [pageId, assets]);

  if (render) {
    return (
      <div className="App">
        <div id="navbar" className="sidebar">
          <div className="titledivcontainer">
            <img src={montylogo} className="montylogo" />
            <div className="titlediv">
              <span className="titletop">Monty</span>
              <span className="titlebottom">PageBuilder</span>
            </div>
          </div>
          <Sidebar />
        </div>
        <div id="main-content" className="navbareditor">
          <TopNav />
          <div id="editor"></div>
          {/* <div id="snackbar">Changes Saved</div>
          <div id="redsnackbar">Try Saving Again</div> */}
          <div className="savedbmodal-background">
            <div className="savedbmodal-loader">
              <span className="savedbsuccess">Saves Changed</span>
              <span className="savedbfail">Facing issues Saving. Please Try Again </span>

              <div className="savedb-spin"></div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Editor;
