import React,{useState} from "react";
import {useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreatePage from "./CreatePage";
import Page from "./Page";
import "../styles/main.css";
import Loader from "./Loader";
import { getpages } from "../redux/actions/pageAction";

export default function PageContainer() {
  const Pagestore = useSelector((state) => state.pageStore);
  const dispatch = useDispatch();


  window.onscroll = async function (ev) {
      if (
        window.innerHeight + window.scrollY+0.3 >=
        document.body.scrollHeight
      ) {
        if(Pagestore.isFetching == false && !Pagestore.lastfetch){
        getpages(Pagestore.numberofpages)(dispatch);
        }
      }
    }

  useEffect(async () => {
    if (Pagestore.pages.length == 0) {
      getpages(15)(dispatch);
    }
  }, []);

  return (
    <div class="pagecontainer">
      <CreatePage />
      {Pagestore.pages.map((page) => {
        return (
          <Page
            name={page.name}
            id={page._id}
            key={page._id}
            background={page.Background_URL}
          />
        );
      })}
      {Pagestore.isFetching && !Pagestore.lastfetch ? <Loader /> : null}
    </div>
  );
}
