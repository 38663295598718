import React,{ useState} from "react";
import "../styles/main.css";
import { RiPagesFill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate  } from "react-router-dom";
import { API_HOST } from "../api_utils";
import { AiFillEye } from "react-icons/ai";
import { deletepage,renamepage } from "../redux/actions/pageAction";

export default function () {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const page = useSelector((state) => state.pageStore.clickedpage);
  const CloseModal = () =>{
    setpagename("")
    var modal = document.getElementById("homemodal");
    var closeicon = document.getElementById("closeicon");
    var maindiv = document.getElementById("maindiv");
    modal.style.right = '-300px';
    maindiv.style.opacity="1"
    closeicon.style.opacity="0"

  }
  const [pagename,setpagename]=useState(page.name)
  const pagenamehandler = (event) =>{
    setpagename(event.target.value)
  }
  const HandleEdit =() => {
        navigate(`/editor/${page.id}`, { replace: true })
  }
  const Handledelete = async () => {
    deletepage(page.id)(dispatch);
    CloseModal()
  }
  const Handlerename = async () => {
    renamepage(page.id,pagename)(dispatch);
    CloseModal()
  }
  const copylink = () => {
    window.open(`https://builder-api.montymobile.com/${page.id}`, "_blank")
  };

  return (
    <div className="homemodal" id ="homemodal">
      <div className="modaltitlediv">
        <RiPagesFill size="25px" className="pageicon" />
        <span className="sidebartitle">{page.name}</span>
        <AiOutlineClose size="16px" className="closeicon"  id="closeicon" onClick={CloseModal}/>
      </div>
      <div className="settingsdiv" id ="settingsdiv">
        <div className="settingsnav">
          <span className="settingstext">Page Settings </span>
        </div>
        <span className="getpagetext">Get Page Link</span>
        <div className="copylinkdiv">
          <input type="text" className="linkinput" value={`${API_HOST}pages/render/${page.id}`} />
          <AiFillEye size="17px" className="copyicon" onClick={copylink} />
        </div>
        <span className="renametext">Rename Page</span>
        <div className="renamediv">
          <input type="text" className="renameinput" placeholder={page.name}  value={pagename} onChange={pagenamehandler} />
          <button size="17px" className="renamebutton" onClick={Handlerename}>
            Rename
          </button>
        </div>
        <span className="actionstext">Actions</span>
        <div className="actionsdiv">
          <button size="17px" className="actionsbutton" onClick={HandleEdit}>
            Edit
          </button>
          <button size="17px" className="actionsbutton" onClick={Handledelete}>
            Delete
          </button>
        </div>
        <div className="sendbysmsdiv">
          <button size="17px" className="sendbysmsbutton" >
            Send Link By SMS
          </button>
      </div>
      </div>
    </div>
  );
}
