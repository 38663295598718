import { UserTypes } from "../actions/userAction";

const {
    StartLogin,
    ErrorLogin,
    LoginDone,
    Logout,
} = UserTypes;

const initialState = {
    user: false || localStorage.getItem("user"),
    isFetching: false,
    error:""
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case StartLogin:
      return {...state,user:action.data.user,isFetching:action.data.isFetching,error:action.data.error};
    case LoginDone:
      return {...state,user:action.data.user,isFetching:action.data.isFetching,error:action.data.error};
    case ErrorLogin:
      return {...state,user:action.data.user,isFetching:action.data.isFetching,error:action.data.error};
    case Logout:
      return {...state,user:action.data.user,isFetching:action.data.isFetching,error:action.data.error};
    default:
      return state;
  }
}

export default userReducer;
