import React, { useState, useEffect } from "react";
import montylogo from "../assets/MONTYLOGO.png";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../redux/actions/userAction";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
export default function () {
  let navigate = useNavigate();
  const loginstatus = useSelector((state) => state.userStore);
  const dispatch = useDispatch();
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  useEffect(async () => {
    if (loginstatus.user == true) {
      navigate("/pages", { replace: true });
    }
  }, [loginstatus]);
  const Loginhandle = async () => {
    login(Username, Password)(dispatch);
  };
  const handleusername = (event) => {
    setUsername(event.target.value);
  };
  const handlepassword = (event) => {
    setPassword(event.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  return (
    <div className="loginpage">
      <div className="logincontainer">
        <img src={montylogo} className="loginlogo" />
        <span className="signintext">Sign in</span>
        <span className="loginsubtitle">
          Sign in and start building your pages !
        </span>
          <input
            placeholder="Enter your username"
            className="usernameinput"
            type="username"
            onChange={handleusername}
          />
          <div className="passworddiv">
          <input
            type={passwordType}
            placeholder="Enter your password"
            className="passwordinput"
            value={Password}
            name="password"
            onChange={handlepassword}
          />
          {passwordType === "password" ? (
            <AiFillEye onClick={togglePassword} className="eye" />
          ) : (
            <AiFillEyeInvisible onClick={togglePassword} className="eye" />
          )}
          </div>
        <div className="loginerrordiv">
          <button className="loginbutton" onClick={Loginhandle}>
            Login
            {loginstatus.isFetching ? <CircularProgress /> : null}
          </button>
          <span className="errortext">{loginstatus.error} </span>
        </div>
      </div>
    </div>
  );
}