import {useState} from "react"
import { MdTableChart } from 'react-icons/md';
import { IoLayers } from 'react-icons/io5';
import { AiFillSetting } from 'react-icons/ai';
import { FaPaintBrush } from 'react-icons/fa';
import $ from "jquery"


function Sidebar() {
  const [activetype,setactivetype]=useState("block")
  const handdleblockbtn = (type)=>{
    setactivetype(type)

  }
  return (
    <>
      <div className=" nav tabs" role="tablist" id="myTab">
        <button className="blocks" onClick={()=>{handdleblockbtn("block")}} id="block-btn" ><MdTableChart className="tabsicon" onClick={()=>{handdleblockbtn("block")}} id="block-icon" size="22px"/></button>
        <button className="blocks"  onClick={()=>{handdleblockbtn("layer")}} id="layer-btn" ><IoLayers className="tabsicon"   onClick={()=>{handdleblockbtn("layer")}} id="layer-icon" size="22px"/></button>
        <button className="blocks"  onClick={()=>{handdleblockbtn("style")}} id="style-btn" ><FaPaintBrush className="tabsicon" onClick={()=>{handdleblockbtn("style")}} id="style-icon" size="22px"/></button>
        <button className="blocks"  onClick={()=>{handdleblockbtn("trait")}} id="trait-btn"  ><AiFillSetting className="tabsicon" onClick={()=>{handdleblockbtn("trait")}} id="trait-icon" size="22px"/></button>

      </div>
      <div className="tab-content">
        <div
          className={activetype == "block" ? "tab-pane fade active show": "tab-pane fade"}
          id="block"
          role="tabpanel"
          aria-labelledby="block-tab"
        >
          <div id="blocks"></div>
        </div>
        <div
          className={activetype == "layer" ? "tab-pane fade active show": "tab-pane fade"}

          id="layer"
          role="tabpanel"
          aria-labelledby="layer-tab"
        >
          <div id="layers-container"></div>
        </div>
        <div
          className={activetype == "style" ? "tab-pane fade active show": "tab-pane fade"}

          id="style"
          role="tabpanel"
          aria-labelledby="style-tab"
        >
          <div id="styles-container"></div>
        </div>
        <div
          className={activetype == "trait" ? "tab-pane fade active show": "tab-pane fade"}
          id="trait"
          role="tabpanel"
          aria-labelledby="trait-tab"
        >
          <div id="trait-container"></div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
