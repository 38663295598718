import { TYPES } from "../actions/pageAction";
import { UserTypes } from "../actions/userAction";


const {
  ClickedPage,
  PagesList,
  DeletedPage,
  RenamePage,
  FetchingPages
} = TYPES;

const {
  ClearRedux
}=UserTypes

const initialState = {
    clickedpage: {},
    pages:[],
    numberofpages:15,
    isFetching:false,
    lastfetch:false
    // pagescroll:false
};

const changename=(id,name,pages)=>{
  for(let i=0;i<pages.length;i++){
    console.log(pages[i]._id)
    if(pages[i]._id == id){
      pages[i].name=name
    }
  }
  return pages;

}
const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ClickedPage:
      return {...state,clickedpage:action.data};
    case PagesList:
        return {...state,pages:state.pages.concat(action.data.pages),isFetching:false,numberofpages:state.numberofpages+15,lastfetch:action.data.lastFetch};
    case FetchingPages:
        return {...state,isFetching:true};    
    case DeletedPage:
        return {...state,pages:state.pages.filter((item)=>{  return item._id !== action.data})};  
    case RenamePage:
        return {...state,pages:changename(action.data.pageid,action.data.name,state.pages)};  
    case ClearRedux:
      return initialState;     
    default:
      return state;
  }
}

export default pageReducer;
