import React, { useEffect, useState } from "react";
import Template from "./Template";
import axios from "axios";
import { API_HOST } from "../api_utils";
import AllTemplates from "../data/Templates";
import CreatePageLoader from "./CreatePageLoader";

export default function Templates() {
  const [templates, settemplates] = useState([]);
  const [viewloader,setviewloader]=useState(false)

  let templatecounter = 1;

  useEffect(async () => {
    const token = localStorage.getItem("user");
    const headers = { authorization: "Bearer ".concat(token) };
    await axios.get(`${API_HOST}templates`, { headers }).then((response) => {
      if (response.data.error) {
        window.localStorage.removeItem("user");
        window.open("/login", "_self");
      } else {
        settemplates(response.data);
      }
    });
  }, []);
  const showloader= (boolean) =>{
    setviewloader(boolean)
  }

  return (
    <div className="createpagecontainer">
              {viewloader? <CreatePageLoader />:null}

      <div className="templatescontainer">
        <Template
          background={""}
          className={"template"}
          title={"Blank Page"}
          id={"blanktemplate"}
          showloader={showloader}
        />
        {templates.map((template) => {
          templatecounter = templatecounter + 1;
          if (templatecounter <= 3) {
            return (
              <Template
                background={template.Background_URL}
                className={"template"}
                title={template.name}
                id={template._id}
                showloader={showloader}

              />
            );
          } else {
            return (
              <Template
                background={template.Background_URL}
                className={"templatemargin"}
                title={template.name}
                id={template._id}
                showloader={showloader}

              />
            );
          }
        })}
      </div>
    </div>
  );
}
