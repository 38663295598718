import React, { useState } from "react";
import "../styles/main.css";
import { MdEdit } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import {useNavigate  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clickpage } from "../redux/actions/pageAction";
import $ from "jquery";

export default function Page(props) {
  const [hover, sethover] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const EditPage = () => {
    navigate(`/editor/${props.id}`, { replace: true })

  };
  const divhoverenter = (event) => {
    sethover(true);
  };
  const divhoverleave = (event) => {
    sethover(false);
  };
  const detailshoverenter = (event) => {};

  const ModalView = () => {
    var closeicon = document.getElementById("closeicon");
    closeicon.style.opacity = "1";
    clickpage(props)(dispatch);
    document.getElementById("homemodal").style.right = "0px";
    document.getElementById("maindiv").style.opacity = "0.2";
  };
  return (
    <div
      className="pagediv"
      id={props.id}
      onMouseEnter={divhoverenter}
      onMouseLeave={divhoverleave}
      onClick={ModalView}
    >
      {hover ? (
        <div className="pagedetails" onMouseEnter={detailshoverenter}>
          <div className="circlesdiv">
            <div className="pagecircle" onClick={EditPage}>
              <MdEdit className="editicon" size={"30px"} />
            </div>
            <div className="pagecircle" onClick={ModalView}>
              <IoSettings className="editicon" size={"25px"} />
            </div>
          </div>
          <span className="editpagetext">{props.name}</span>
        </div>
      ) : null}
      <div
        className="pagebackground"
        style={{
          backgroundImage: "url(" + props.background + ")",
        }}
      ></div>
    </div>
  );
}
