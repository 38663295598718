import React from "react";
import HomeSidebar from "../components/HomeSidebar";
import Templates from "../components/Templates";
import { IoCall } from "react-icons/io5";
import Pagehead from "../components/Pagehead";


import "../styles/main.css";

export default function CreatePage() {

  return (
    <>
      <HomeSidebar />
      <div className="maindiv" id="maindiv">
        <Pagehead pagename={"Create Page"} />
        <Templates />

      </div>
    </>
  );
}
