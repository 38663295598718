import grapesjs from "grapesjs";
import { API_HOST } from ".";

import gjsBlockBasic from "grapesjs-blocks-basic";
import $ from "jquery";
import grapesjsPluginExport from "grapesjs-plugin-export";
import grapesjsStyleBg from "grapesjs-style-bg";
import grapesjsNavbar from "grapesjs-navbar";
import gjsForms from "grapesjs-plugin-forms";
import grapesjstabs from "grapesjs-tabs";
import "grapesjs/dist/css/grapes.min.css";


import {
  addEditorCommand,
  deviceManager,
  layerManager,
  panels,
  scripts,
  selectorManager,
  storageSetting,
  styleManager,
  styles,
  traitManager,
} from "./geditor_utils";

const geditorConfig = (pageId) => {
  $(".panel__devices").html("");
  $(".panel__basic-actions").html("");
  $(".panel__editor").html("");
  $("#blocks").html("");
  $("#styles-container").html("");
  $("#layers-container").html("");
  $("#trait-container").html("");

  // Content for Preview
  const sidebar = $("#navbar");
  const mainContent = $("#main-content");
  const panelTopBar = $("#main-content > .navbar-light");
  const editor = grapesjs.init({
    container: "#editor",
    blockManager: {
      appendTo: "#blocks",
    },
    panels: { defaults: [] },
    avoidInlineStyle: true,
    avoidInlineStyles: 0,
    styleManager: styleManager,
    layerManager: layerManager,
    traitManager: traitManager,
    selectorManager: selectorManager,
    panels: panels,
    deviceManager: deviceManager,
    // assetManager: {upload: false },
    storageManager: storageSetting(pageId),
    canvas: {
      styles: styles,
      scripts: scripts,
    },
    plugins: [
      gjsBlockBasic,
      grapesjsPluginExport,
      grapesjsStyleBg,
      grapesjsNavbar,
      gjsForms,
      grapesjstabs,
    ],
    pluginsOpts: {
      gjsBlockBasic: {
        stylePrefix: "", // no gjs- prefix
        flexGrid: 1, // use flexbox instead of tables
      },
      grapesjsPluginExport: {},
      grapesjsStyleBg: {},
      gjsForms: {},
      grapesjstabs:{},
    },
    canvasCss: `

    .gjs-row {
      display: flex !important;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      padding: 10px;
    }
    .gjs-row > .gjs-cell {
      min-height: 30px;
      flex-grow: 1;
      flex-basis: 100%;
      height:unset;
      padding:7px;
    }

      
  `,
  });

  addEditorCommand(editor);

  editor.DomComponents.addType('form', {
    isComponent: el => el.tagName == 'FORM',
    model: {
      defaults: {
        tagName: 'form',
        id: 'form',
        droppable: ':not(form)',
        draggable: ':not(form)',
        attributes: { method: 'Post'},
        traits: [{
          type: 'select',
          name: 'method',
          className:"getpostmethod",
          options: [
            {value: 'post', name: 'POST'},
            {value: 'get', name: 'GET'},
          ],
        },
         {
          name: 'action',
          value:`${API_HOST}pages/form/sendmail`
        },
        {
          type: 'email',
          name: 'email',
          label: 'email',
        }
        ,
        {
          name:'target',
          value:"dummyframe"
        }

      ],
      },
    },
   

    view: {
      events: {
        submit: e => e.preventDefault(),
      }
    },
  });


  editor.DomComponents.addType('button', {
    isComponent: el => el.tagName == 'BUTTON',

    model: {
      defaults: {
        tagName: 'button',
        attributes: { type: 'submit',class:"submitform" },
        text: 'Send',
        traits: [
          {
            name: 'text',
            changeProp: true,
          }, {
            type: 'select',
            name: 'type',
            options: [
              { value: 'submit' },
              { value: 'reset' },
            ]
        }]
      },

      init() {
        const comps = this.components();
        const tChild =  comps.length === 1 && comps.models[0];
        const chCnt = (tChild && tChild.is('textnode') && tChild.get('content')) || '';
        const text = chCnt || this.get('text');
        this.set({ text });
        this.on('change:text', this.__onTextChange);
        (text !== chCnt) && this.__onTextChange();
      },

      __onTextChange() {
        this.components(this.get('text'));
      },
    },

    view: {
      events: {
        click: e => e.preventDefault(),
      },
    },
  });
  editor.TraitManager.addType('email', {
    onEvent({ elInput, component, event }) {
      component.getTrait('action').set({value:`${API_HOST}pages/form/sendmail?pageid=`+window.location.href.split("/")[4]+'&email='+event.target.value});
    }
  })


  const cmp = editor.Components;

  editor.BlockManager.remove('column3-7')



  editor.on("run:preview", () => {
    // console.log("It will trigger when we click on preview icon");
    // // This will be used to hide border
    editor.stopCommand("sw-visibility");
    // // This will hide the sidebar view
    // navbar.removeClass("sidebar");
    // // This will make the main-content to be full width
    // mainContent.removeClass("main-content");

    // // This will hide top panel where we have added the button
    panelTopBar.addClass("d-none");
    mainContent.addClass("previewmaincontent");
    sidebar.addClass("removesidebar");
  });
  editor.on("stop:preview", () => {
    // This event is reverse of the above event.
    // console.log("It will trigger when we click on cancel preview icon");
    editor.runCommand("sw-visibility");
    // navbar.addClass("sidebar");
    // mainContent.addClass("main-content");
    // panelTopBar.removeClass("d-none");
    panelTopBar.removeClass("d-none");
    mainContent.removeClass("previewmaincontent");
    sidebar.removeClass("removesidebar");
  });
  editor.on("component:selected", (component) => {
    // console.log("component :>> ", component.getAttributes());
    // console.log(component.setAttributes({ id: 'action', 'data-key': 'hahha' }));
    component.getAttributes().action="sdds"
    // toggleSidebar(!!component);
  });

  setTimeout(() => {
    let categories = editor.BlockManager.getCategories();
    categories.each((category) => category.set("open", false));
  }, 1000);
  return editor;
};

export default geditorConfig;
