import React from "react";
export default function CreatePageLoader() {
  return (
    <div className="modal-background">
      <div className="modal-loader">
        <div className="createpage-loader"></div>
      </div>
    </div>
  );
}
