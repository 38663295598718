import React, { useState } from "react";
import "../styles/main.css";
import PageContainer from "../components/PageContainer";
import SettingsModal from "../components/SettingsModal";
import HomeSidebar from "../components/HomeSidebar";
import Pagehead from "../components/Pagehead";

const Home = () => {
  return(
    <>
      <HomeSidebar/>
      <div className="maindiv" id="maindiv">
        <Pagehead pagename={"Pages"}/>
        <PageContainer />
      </div>
      <SettingsModal />
    </>
  )
};

export default Home;
