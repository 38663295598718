import axios from "axios";
import { API_HOST } from "../../api_utils";

export const UserTypes = {
  StartLogin:"StartLogin",
  ErrorLogin:"ErrorLogin",
  LoginDone:"LoginDone",
  Logout:"Logout",
  ClearRedux:"ClearRedux"
};

export const login =(Username,Password) =>async (dispatch)=>{
  if(Username=="" || Password==""){
    dispatch({ type: UserTypes.ErrorLogin, data: {user:false,isFetching:false,error:"Both fields are required"} })
  }else{
  dispatch({ type: UserTypes.StartLogin, data: {user:false,isFetching:true,error:""} })
    await axios.post(`${API_HOST}users/login`,{Username,Password}).then((response)=>{
        if(response.data.error){
          dispatch({ type: UserTypes.ErrorLogin, data: {user:false,isFetching:false,error:"invalid credentials"} })
        }else{
          localStorage.setItem('user',response.data.token)
          dispatch({ type: UserTypes.LoginDone, data: {user:true,isFetching:false,error:""} })
        }
      });
    }
}
export const logout =(Username,Password) =>async (dispatch)=>{
  dispatch({ type: UserTypes.Logout, data: {user:false,isFetching:false,error:""} })
  dispatch({ type: UserTypes.ClearRedux})

}