import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_HOST } from "../api_utils";
import { AiFillEye } from "react-icons/ai";
import {
  BsFillArrowUpCircleFill,
  BsArrowDownCircleFill,
  BsFileEarmarkFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { createPage } from "../redux/actions/pageAction";

export default function Template(props) {
  const handlerup = () => {
    sethandleup(true);
    document.getElementById(props.id).style.height = "70%";
  };
  const handlerdown = () => {
    sethandleup(false);
    document.getElementById(props.id).style.height = "15%";
  };

  let navigate = useNavigate();
  const [handleup, sethandleup] = useState(false);
  const [name, setname] = useState("");
  const [error, seterror] = useState("");

  const OnPageNameChange = (event) => {
    setname(event.target.value);
  };
  const displaytemplate = () => {
    window.open(`https://builder-api.montymobile.com/template/${props.id}`, "_blank");
  };
  const CreatePageHandler = async () => {
    const _id = props.id;
    const token = localStorage.getItem("user");
    const headers = { authorization: "Bearer ".concat(token) };
    props.showloader(true);
    if (_id == "blanktemplate") {
      await axios
        .post(`${API_HOST}pages/`, { name }, { headers })
        .then((response) => {
          if (response.data.error) {
            if (
              response.data.error == "You Are Not Authenticated" ||
              response.data.error == "Failed To Authenticate"
            ) {
              window.localStorage.removeItem("user");
              window.open("/login", "_self");
            } else {
              seterror(response.data.error);
            }
          } else {
            navigate(`/editor/${response.data._id}`, { replace: true });
          }
        });
    } else {
      await axios
        .post(`${API_HOST}templates/createPageTemp`, { name, _id }, { headers })
        .then((response) => {
          if (response.data.error) {
            if (
              response.data.error == "You Are Not Authenticated" ||
              response.data.error == "Failed To Authenticate"
            ) {
              window.localStorage.removeItem("user");
              window.open("/login", "_self");
            } else {
              seterror(response.data.error);
            }
          } else {
            navigate(`/editor/${response.data._id}`, { replace: true });
          }
        });
    }
    props.showloader(false);
  };

  return (
    <div
      className={props.className}
      style={{
        backgroundImage: "url(" + props.background + ")",
        backgroundSize: "cover",
      }}
    >
      {props.title == "Blank Page" ? (
        <BsFileEarmarkFill size="30px" color="#ff146b" />
      ) : null}

      <div className="templateinnerdiv" id={props.id}>
        {handleup == false ? (
          <>
            <div className="arrowdiv">
              <BsFillArrowUpCircleFill
                size="30px"
                className="arrowup"
                onClick={handlerup}
              />
            </div>
            <div className="initialtitlediv">
              <span className="templatename">{props.title}</span>
            </div>
          </>
        ) : (
          <>
            <div className="arrowdiv">
              <BsArrowDownCircleFill
                size="30px"
                className="arrowup"
                onClick={handlerdown}
              />
            </div>
            <div className="upshowndiv">
              <div className="templatenamediv">
                <span className="templatename">{props.title}</span>
              </div>
              <div className="templateinputdiv">
                <input
                  type="text"
                  className="templateinput"
                  value={name}
                  onChange={OnPageNameChange}
                  placeholder="Enter Page Name "
                />
              </div>
              <span className="templateerrortext">{error}</span>
              <div className="templatebuttons">
                <button
                  className="createpagebutton"
                  onClick={CreatePageHandler}
                >
                  Create
                </button>
                {props.id !== "blanktemplate" ? (
                  <button className="displaybutton" onClick={displaytemplate}>
                    <AiFillEye className="displayicon" size="20px" />
                  </button>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
