import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_HOST } from "./api_utils";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useParams 
} from "react-router-dom";
import UserPages from "./Pages/UserPages";
import CreatePage from "./Pages/CreatePage";
import "./styles/main.css";
import Login from "./Pages/Login";
import Editor from "./Pages/Editor";
import { useSelector, useDispatch } from "react-redux";
import Healthcheck from "./Pages/Healthcheck";

function App() {
  const loginstatus = useSelector((state) => state.userStore);


  // const checktokenvalid= async () =>{
  //   const token=localStorage.getItem("user")
  //   console.log("hi")

  //   await axios.post(`http://localhost:5555/checktokenvalid`,{token}).then((response)=>{
  //     if(response.data.error){
  //       console.log("notauthenticated")
  //         return false;
  //     }else{
  //       console.log("authenticateddd")
  //       return true;
  //     }
  //   });
  // }

  return (
    <Router>
      <Routes>
        {loginstatus.user  ? (
          <>
            <Route  path="*" element={<Navigate to="/pages" replace={true} />}></Route>
            <Route  path="/pages" element={<UserPages />}></Route>
            <Route  path="/CreatePage" element={<CreatePage />}></Route>
            <Route  path="/editor/:pageId" element={<Editor />}></Route>
            <Route  path="/healthcheck" element={<Healthcheck />}></Route>
          </>
        ) : (
          <>
          <Route exact path="*" element={<Navigate to="/login" replace={true} />}></Route>
          <Route  path="/login" element={<Login />}></Route>
          <Route  path="/healthcheck" element={<Healthcheck />}></Route>

          </>
        )}
      </Routes>
    </Router>
  );
}


export default App;
