import React from 'react'
import { AiFillHome } from "react-icons/ai";
import user from "../assets/user.png";

export default function Pagehead(props) {
  return (
    <div className="pageheaddiv">
      <AiFillHome size="15px" />
      <span className="pageheadspan">Home / {props.pagename}</span>
    </div>
  )
}
