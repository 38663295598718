import React from "react";
import { MdEdit,MdOutlinePostAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import {useNavigate  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/actions/userAction";



export default function HomeSidebar() {
  let navigate = useNavigate();
  const loginstatus = useSelector((state) => state.userStore);
  const dispatch = useDispatch();

  const logouthandler = () =>{
    logout()(dispatch);
    window.localStorage.removeItem('user');
    navigate("/login", { replace: true })
  }
  return (
    <div className="homesidebar">
      <div className="sidebarheader" >
        <div className="homesidebartitlediv">
          <span className="homesidebartitletop">MONTY</span>
          <span className="homesidebartitlebottom">PAGE BUILDER</span>
        </div>
      </div>
      <div className="sidebarcontent">
        <Link to="/pages" style={{ textDecoration: 'none' }}>
          <div className="sidebartab">
            <MdEdit className="sidebartabicon" size={"22px"} />
            <button className="sidebartabbutton">Pages</button>
          </div>
        </Link>

        <Link to="/CreatePage" style={{ textDecoration: 'none' }}>
          <div className="sidebartab">
            <MdOutlinePostAdd className="sidebartabicon" size={"22px"} />
            <button className="sidebartabbutton">Templates</button>
          </div>
        </Link>
      </div>
    <div className="sidebarlogoutdiv">
      <button className="logoutbutton" onClick={logouthandler}>Logout</button>
    </div>
    </div>
  );
}